import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConnectorService, UserVariable } from '../connector.service';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  showPreferences = false; // Par défaut, caché

  constructor(
    private http: HttpClient,
    private connector: ConnectorService,
    public notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    if(!this.connector.isConnected()){
      this.connector.disconnection();
    }
  }

  showTooltip(tooltip: any) {
    tooltip.show();  // Afficher immédiatement
    setTimeout(() => tooltip.hide(), 3000); // Disparaît après 3 sec
  }
  

  togglePreferences() {
    this.showPreferences = !this.showPreferences;
  }

  async changeNotificationsReceive(){
    await this.notificationsService.changeNotificationsReceive();
  }

  getUsername():string{
    return this.connector.getValue(UserVariable.USERNAME);
  }

  getEmail():string{
    return this.connector.getValue(UserVariable.EMAIL);
  }

  isAdherent():boolean{
    return this.connector.isAdherent();
  }
  
  disconnect() {
    this.connector.disconnection();
  }
}
