<nav class="sectionNavbar">
    <mat-toolbar color="primary" class="navbar">
        <span class="logoText">My</span><img src="assets/logos/logo-sociochaux@3x-150x134.png" (click)="logoClick()" class="custom-logo" alt="Logo Sociochaux, un lion rugissant vers l’avenir, car Sochaux vivra" decoding="async" srcset="assets/logos/logo-sociochaux@3x-150x134.png 150w, assets/logos/logo-sociochaux@3x-300x269.png 300w, assets/logos/logo-sociochaux@3x-1024x917.png 1024w, assets/logos/logo-sociochaux@3x-768x688.png 768w, assets/logos/logo-sociochaux@3x-1536x1376.png 1536w, assets/logos/logo-sociochaux@3x.png 1563w" class="logo">
        <span class="example-spacer"></span>
        <div>
            <button mat-raised-button color="accent" id="install" *ngIf="isInstallable()"><mat-icon>install_mobile</mat-icon><span [fxHide.lt-sm]="true">MySocio</span></button>
        </div>
    </mat-toolbar>
    <mat-toolbar *ngIf="isConnected" color="primary" class="menu-toolbar">
        <button mat-mini-fab class="menu-button" [color]="colorHere('/home')" aria-label="Accueil" [routerLink]="['/home']">
            <mat-icon>home</mat-icon>
        </button>
        <button mat-mini-fab class="menu-button" [color]="colorHere('/account')" aria-label="Mon compte" [routerLink]="['/account']">
            <mat-icon [matBadge]="notificationsService.notifications.compteIssues+notificationsService.notifications.socioIssues" matBadgeColor="warn" [matBadgeHidden]="getHidden(notificationsService.notifications.compteIssues+notificationsService.notifications.socioIssues)">account_circle</mat-icon>
        </button>
        <button mat-mini-fab class="menu-button" [color]="colorHere('/pronos')" aria-label="Pronostics" [routerLink]="['/pronos']">
            <mat-icon [matBadge]="notificationsService.notifications.missingPronostics" matBadgeColor="warn" [matBadgeHidden]="getHidden(notificationsService.notifications.missingPronostics, notificationsService.notifications.receivePronoNotifications)">check_box</mat-icon>
        </button>
        <button mat-mini-fab class="menu-button" [color]="colorHere('/consultations')" aria-label="Consultations" [routerLink]="['/consultations']">
            <mat-icon [matBadge]="notificationsService.notifications.consultationsAvailable" matBadgeColor="warn" [matBadgeHidden]="getHidden(notificationsService.notifications.consultationsAvailable)">how_to_vote</mat-icon>
        </button>
        <button mat-mini-fab class="menu-button" [color]="colorHere('/documents')" aria-label="Documents"[routerLink]="['/documents']">
            <mat-icon [matBadge]="notificationsService.notifications.unreadDocs" matBadgeColor="warn" [matBadgeHidden]="getHidden(notificationsService.notifications.unreadDocs)">folder_open</mat-icon>
        </button>
    </mat-toolbar>
</nav>