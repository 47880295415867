import {inject, Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { ConnectorService } from "./connector.service";
import { SwPush } from "@angular/service-worker";
import { MatSnackBar } from "@angular/material/snack-bar";

export class InAppNotification {
    receiveImportantNotifications: boolean = false;
    receiveAssociativeNotifications: boolean = false;
    receivePronoNotifications: boolean = false;
    unreadDocs: number = 0;
    missingPronostics: number = 0;
    compteIssues: number = 0;
    socioIssues: number = 0;
    consultationsAvailable: number = 0;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    public notifications: InAppNotification = new InAppNotification();
    notificationsObs: Observable<InAppNotification> = new Observable<InAppNotification>();
    private _snackBar = inject(MatSnackBar);

    private pushNotifications: { title: string; body: string; timestamp: number; opened: boolean }[] = [];

    constructor(
        private http: HttpClient,
        private connector: ConnectorService,
        private swPush: SwPush
    )
    {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', (event) => {
              if (event.data?.type === 'NOTIFICATION_RECEIVED' || event.data?.type === 'PUSH') {
                this.addPushNotification(event.data.data);
              }
            });
        }
    }

    getPushNotifications() {
        return this.pushNotifications;
    }

    async changeNotificationsReceive(){
        const toBeSent = {
            receiveImportantNotifications: this.notifications.receiveImportantNotifications,
            receiveAssociativeNotifications: this.notifications.receiveAssociativeNotifications,
            receivePronoNotifications: this.notifications.receivePronoNotifications
        };
        await this.http.post(environment.backAPI+'/notifications/notifications', toBeSent, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ this.connector.token()
            }
        }).subscribe();
    }

    addPushNotification(notification: { title: string; body: string }) {
        this._snackBar.open(notification.title, 'Fermer');
        this.pushNotifications.unshift({
            title: notification.title,
            body: notification.body,
            timestamp: Date.now(),
            opened: false
        });
    }
  
    async getInAppNotifications(){
        const _this = this;
        _this.notificationsObs = await this.http.get<InAppNotification>(environment.backAPI+'/inapp-notifications',{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ this.connector.token()
        }
        });
        _this.notificationsObs.subscribe(
        (result) => {
            if(result !== undefined && !(result instanceof HttpErrorResponse)){
                _this.notifications = result;
            return result;
            }
            else{
            this.connector.disconnection();
            return [];
            }
        },
        (error) => {
            this.connector.disconnection();
            return [];
        }
        );
    }
}