<div class="dialog-container">
    <div *ngIf="data.url && !isPDF" class="dialog embed-container">
      <embed [src]="data.url" class="responsive-embed" type="{{data.document.type}}" />
      <button mat-mini-fab color="warn" (click)="onNoClick()">
          <mat-icon>close</mat-icon>
      </button>
    </div>
    <div *ngIf="data.url && isPDF" class="dialog pdf-container">
      <canvas id="pdf-canvas"></canvas>
    </div>
    <div *ngIf="data.url && isPDF" class="pdf-navigation">
        <button mat-mini-fab color="primary" (click)="prevPage()" [disabled]="currentPage === 1">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <span>{{ currentPage }} / {{ totalPages }}</span>
        <button mat-mini-fab color="primary" (click)="nextPage()" [disabled]="currentPage === totalPages">
            <mat-icon>chevron_right</mat-icon>
        </button>
        <button mat-mini-fab color="warn" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
  </div>
  