<mat-tab-group fxLayout="column" fxFlex="90" fxLayoutAlign="center center" style="margin-left:auto; margin-right:auto">
  <mat-tab label="Pronostics" fxLayout="column" fxFlex="90" fxLayoutAlign="center center">
    <div fxLayout="column" *ngFor="let pari of currentParis" class="pronostic">
      <strong>[{{pari.categorie.titre}}] {{ pari.titre }}</strong>
      <mat-chip-listbox aria-label="Choisir un pronostic"
        [value]="(pari.selectedOption !== undefined)?pari.selectedOption!.id:0" (change)="setOption(pari, $event.value)">
        <mat-chip-option *ngFor="let option of pari.options" disableripple="true" [selectable]="!isSelected(pari, option.id)" [ngClass]=""
          [value]="option.id">{{option.titre}} ({{option.score}}pts)</mat-chip-option>
      </mat-chip-listbox>
      <p style="font-style: italic; font-size: small;">Modification possible jusqu'au {{pari.date_fin|
        date:'dd/MM/yyyy'}} à {{pari.date_fin| date:'HH:mm'}}</p>
      <mat-divider></mat-divider>
    </div>
  </mat-tab>
  <mat-tab label="Historique" fxLayout="column" fxFlex="90">
    <div flex="" fxLayout="row" fxLayoutAlign="left">
      <mat-form-field fxFlex="100">
        <mat-label>Quel historique ?</mat-label>
        <mat-select [(ngModel)]="selectedCategory" name="categories" (ngModelChange)="changeHistoric($event)">
          <mat-option *ngFor="let categorie of categories" [value]="categorie">
            {{categorie.titre}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <table *ngIf="historicPronosticsToDisplay !== undefined" mat-table [dataSource]="historicPronosticsToDisplay"
      class="mat-elevation-z8" fxFlex="90" class="historic">
      <ng-container matColumnDef="pronostic">
        <th mat-header-cell *matHeaderCellDef> Pronostic </th>
        <td mat-cell *matCellDef="let element" class="line-{{getClassScore(element)}}">
          [{{element.categorie}}]<br />{{element.pari}} </td>
      </ng-container>
      <ng-container matColumnDef="option">
        <th mat-header-cell *matHeaderCellDef> Choix </th>
        <td mat-cell *matCellDef="let element"> {{element.option_label}} </td>
      </ng-container>
      <ng-container matColumnDef="gagnant">
        <th mat-header-cell *matHeaderCellDef> Gagnant? </th>
        <td mat-cell *matCellDef="let element"> <mat-icon>{{element.gagnant}}</mat-icon> </td>
      </ng-container>
      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef> Score </th>
        <td mat-cell *matCellDef="let element"> {{element.score}}{{(element.score!=="-")?'pts':""}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="historicColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: historicColumns;"></tr>
    </table>
  </mat-tab>
  <mat-tab label="Classements" fxLayout="column" fxFlex="90">
    <div flex="" fxLayout="row" fxLayoutAlign="left">
      <mat-form-field fxFlex="100">
        <mat-label>Quel classement ?</mat-label>
        <mat-select [(ngModel)]="selectedRank" name="selectedRank" (ngModelChange)="changeRank($event)">
          <mat-option *ngFor="let rank of rankList" [value]="rank.index">
            {{rank.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="currentRank !== undefined" fxLayout="column" fxFlex="90" style="margin-bottom:25px"
      fxLayoutAlign="center center" class="rank">
      <h2 fxLayoutAlign="center center">Classement {{ currentRank.title }}</h2>
      <table mat-table [dataSource]="currentRank.ranks" class="mat-elevation-z8" fxFlex="90" style="margin-left: auto; margin-right: auto;">
        <ng-container matColumnDef="rank">
          <th mat-header-cell *matHeaderCellDef> Rang </th>
          <td mat-cell *matCellDef="let element; let i = index;">{{i+1}}</td>
        </ng-container>
        <ng-container matColumnDef="compteName">
          <th mat-header-cell *matHeaderCellDef> Nom </th>
          <td mat-cell *matCellDef="let element" [ngClass]="isCurrentCompte(element.compteId)?'myLine':'otherLine'">
            {{(element.username !== null)?element.username:'Anonyme_'+element.compteId}} </td>
        </ng-container>
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef> Nb pronos </th>
          <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>
        <ng-container matColumnDef="score">
          <th mat-header-cell *matHeaderCellDef> Score </th>
          <td mat-cell *matCellDef="let element">{{element.score}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="rankColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: rankColumns;"></tr>
      </table>
    </div>
  </mat-tab>
</mat-tab-group>