import { Component, inject } from '@angular/core';
import packageJson from '../../package.json';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ConnectorService } from './connector.service';
import { SwPush } from '@angular/service-worker';
import { NotificationsService } from './notifications.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sociochaux',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Sociochaux : Espaces Personnels';
  public version: string = packageJson.version;

  readonly VAPID_PUBLIC_KEY = "BBPqTsDtCtza5gTuo1nERE7iq4Bvpzy1qyvmGhEGeokhxpHhPrMgZNZg_c3jnAkIpo-IRlRfZS-_IqZkIFU5bVY";
  eventSource:EventSource|null = null;

  constructor(
    private connector: ConnectorService,
    private swPush: SwPush,
    public notificationsService: NotificationsService,
    private http: HttpClient
  ){}

  ngOnInit(): void {
    if(this.connector.isConnected() && this.swPush.isEnabled){
      console.log('Notifications activées');
      this.subscribeToNotifications();
    }
  }

  subscribeToNotifications() {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    }).then(subscription => {
      // Envoie l'abonnement au backend
      this.http.post(environment.backAPI+'/notifications/subscription', subscription, {
        headers: {
          'Authorization': 'Bearer '+this.connector.token()
        }
      }).subscribe();
    }).catch(err => console.error('Échec de l’abonnement', err));
  }

  getYear(){
    return (new Date()).getFullYear();
  }

  isConnected(){
    return this.connector.isConnected();
  }
}
