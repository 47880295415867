<div fxLayout="column" fxFlex="100" class="columnDiv">
    <h2 fxLayout="row" fxFlex="100" fxLayoutAlign="center center">Documents</h2>
    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
        <mat-card *ngFor="let file of fileList" fxLayout="column" fxFlex="100" style="margin-top:10px;" class="columnDiv">
            <mat-card-title fxFlex="100" fxLayoutAlign="center center">
                {{ file.name }}<span *ngIf="file.size !== null">&nbsp;- {{ formatBytes(file.size,2) }}</span>
            </mat-card-title>
            <mat-card-content fxFlex="100" class="columnDiv">
                <mat-divider></mat-divider>
                <p>
                    <strong>&Eacute;dition:</strong>&nbsp;{{ file.update | date:'dd/MM/yyyy' }}
                </p>
                <p><strong>Description:</strong> {{ file.description }}</p>
            </mat-card-content>
            <mat-card-actions fxFlex="100" fxLayoutAlign="center center">
                <button mat-raised-button *ngIf="file.type !== null" (click)="fileDownload(file.id)">{{ file.filename }}</button> <button mat-raised-button disabled *ngIf="file.type === null">Aucun fichier chargé</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>