import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ConnectorService } from '../connector.service';
import { environment } from '../../environments/environment';
import { CategorieDto, CurrentPronos, HistoricProno, PariDto, PariStatus, PronosticDto, ScorePronoDayDto, ScorePronoDto } from './pronos.dto';

@Component({
  selector: 'app-pronos',
  templateUrl: './pronos.component.html',
  styleUrls: ['./pronos.component.scss']
})
export class PronosComponent {
  currentParis:PariDto[] = [];
  currentPronostics: PronosticDto[] = [];
  historicPronostics: HistoricProno[] = [];
  historicPronosticsToDisplay: HistoricProno[] = [];
  selectedCategory: CategorieDto|null = null;
  categories: CategorieDto[] = [];
  rankList: { index: number; title: string; ranks: (ScorePronoDto|ScorePronoDayDto)[] }[] = [];
  currentRank: { index: number; title: string; ranks: (ScorePronoDto|ScorePronoDayDto)[] }|undefined = undefined;
  selectedRank: number = 0;
  
  historicColumns: string[] = ['pronostic', 'option', 'gagnant', 'score'];
  rankColumns: string[] = ['rank', 'compteName', 'nombre', 'score'];

  constructor(
    private http: HttpClient,
    private connector: ConnectorService
  ){}

  ngOnInit(): void {
    if(!this.connector.isConnected()){
      this.connector.disconnection();
    }
    this.getCurrentParis();
    this.getHistoric();
    this.getRanks();
  }

  getClassScore(element:HistoricProno){
    return (element.score==="-")?"waiting":element.score;
  }

  isCurrentCompte(idCompte:number){
    return idCompte === this.connector.getId();
  }

  findProno(pariId:string){
    const found:PronosticDto|undefined = this.currentPronostics.find(prono => prono.option.pari.id === pariId);
    if(found !== undefined){
      return null;
    }
    return found;
  }

  isSelected(pari:PariDto, optionid:string){
    const selectedOption = (pari.selectedOption !== undefined)?pari.selectedOption!.id:0;
    return selectedOption === optionid;
  }
  
  async getCurrentParis(){
    const _this = this;
    await this.http.get<CurrentPronos>(environment.backAPI+'/paris/public/paris/v2',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          _this.currentParis = result.paris;
          _this.currentPronostics = result.pronostics;
          for(let paris of _this.currentParis){
            paris.options = paris.options.sort((n1,n2) => {
              if (n1 > n2) {
                return 1;
              }
          
              if (n1 < n2) {
                  return -1;
              }
          
              return 0;
            });
          }
          for(let prono of _this.currentPronostics){
            const pari:PariDto|undefined = _this.currentParis.find(pari => pari.id === prono.option.pari.id);
            if(pari !== undefined){
              pari.selectedOption = prono.option;
            }
          }
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }
  
  
  async getHistoric(){
    const _this = this;
    await this.http.get<HistoricProno[]>(environment.backAPI+'/paris/public/historic/v2',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          _this.historicPronostics = [];
          _this.historicPronosticsToDisplay = [];
          _this.categories = [];
          for(let prono of result){
            if(!_this.categories.find((cat) => cat.id === prono.option.pari.categorie.id)){
              _this.categories.push(prono.option.pari.categorie);
            }
            _this.historicPronostics.push({
              categorie: prono.option.pari.categorie.titre,
              pari: prono.option.pari.titre,
              option: prono.option,
              option_label: prono.option.titre,
              gagnant: (prono.option.pari.status === PariStatus.WAITING)?"question_mark":(prono.option.isValidated)?"thumb_up":"thumb_down",
              score: (prono.option.pari.status === PariStatus.WAITING)?'-':(prono.option.isValidated)?prono.option.score+'':'0'
            });
          }
          if(_this.categories.length > 0){
            _this.selectedCategory = _this.categories[0];
            _this.changeHistoric(_this.selectedCategory);
          }
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }

  changeHistoric(cat:CategorieDto){
    this.historicPronosticsToDisplay = this.historicPronostics.filter((prono)=>prono.option.pari.categorie.id === cat.id);
  }
  
  async getRanks(){
    const _this = this;
    await this.http.get<{ranksByCat: ScorePronoDto[], ranksByDay: ScorePronoDayDto[]}>(environment.backAPI+'/paris/public/rank/v2',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          _this.rankList = [];
          let intermediateList:{ index: number, update: Date, title: string, ranks: (ScorePronoDto | ScorePronoDayDto)[] }[] = [];
          for(let currentRank of result.ranksByCat){
            let elementRank = intermediateList.find(foundRank => foundRank.ranks[0].categorieId === currentRank.categorieId);
            if(elementRank === undefined){
              elementRank = { index: _this.rankList.length+1, update: new Date(currentRank.last_update), title: currentRank.categorieName, ranks: [] };
              _this.rankList.push(elementRank);
              intermediateList.push(elementRank);
            }
            elementRank?.ranks.push(currentRank);
          }
          intermediateList = [];
          for(let currentRank of result.ranksByDay){
            let elementRank = intermediateList.find(foundRank => foundRank.ranks[0].last_update === currentRank.last_update);
            if(elementRank === undefined){
              elementRank = { index: _this.rankList.length+intermediateList.length+1, update: new Date(currentRank.last_update), title: 'Jour: '+_this.completeCar(currentRank.jour,2)+'/'+_this.completeCar(currentRank.mois,2)+'/'+_this.completeCar(currentRank.annee,4), ranks: [] };
              intermediateList.push(elementRank);
            }
            elementRank?.ranks.push(currentRank);
          }
          intermediateList = intermediateList.sort((n1,n2) => {
            if (n1.update < n2.update) {
                return 1;
            }
        
            if (n1.update > n2.update) {
                return -1;
            }
        
            return 0;
          });
          for(let element of intermediateList){
            _this.rankList.push(element);
          }
          if(_this.rankList.length > 0){
            _this.selectedRank = _this.rankList[0].index;
            _this.changeRank(_this.selectedRank);
          }
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }

  changeRank(index:number){
    this.currentRank = this.rankList.find((rank) => rank.index === index);
  }
  
  completeCar(num:number, length:number){
    let result:string = num+'';
    for(let i=result.length; i<length;i++){
      result = '0'+result;
    }
    return result;
  }

  async setOption(pari:PariDto, optionId:string){
    const _this = this;
    pari.selectedOption = pari.options.find(option => option.id === optionId);
    await this.http.get<Boolean>(environment.backAPI+'/paris/public/choose-option/'+optionId,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          if(!result){
            alert("Votre modification n'a pas pu être prise en compte");
            _this.getCurrentParis();
          }
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }
}
