<mat-stepper
  orientation="vertical"
  [linear]="isLinear"
  #stepper
  class="mainDiv"
  [selectedIndex]="selectedStepIndex"
  *ngIf="messagePaiement === undefined && messageIntent === undefined">
  <mat-step [stepControl]="adhesionFormGroup" flex="" fxLayout="row">
    <form [formGroup]="adhesionFormGroup" fxLayout="column">
      <ng-template matStepLabel>Adhérez à l'association Sociochaux</ng-template>
      <mat-label>Choisissez votre engagement <strong *ngIf="nbAdherents > 0">(déjà {{nbAdherents}} adhérents !)</strong></mat-label>
      <mat-chip-listbox
        formControlName="adhesionCtrl"
        class="mat-mdc-chip-set-stacked"
        aria-label="Paliers sélectionnables"
        [(ngModel)]="selectedPalier"
        (change)="changePalier($event)">
        <mat-chip-option class="choices" *ngFor="let palier of adhesionPaliers" [value]="palier.id" [disabled]="!palier.selectable" [selectable]="selectedPalier !== palier.id && palier.selectable">
          {{ palier.text }} ({{palier.price}}€) <br />
          <strong>{{ !palier.selectable ? '[COMPLET]' : palier.nb >= 0 ? '['+palier.nb+'/'+palier.max+']':''}}</strong>
        </mat-chip-option>
      </mat-chip-listbox>
      <div fxFlex="80" fxLayoutAlign="center" *ngIf="hasComplement(selectedPalier)">
          <mat-form-field fxFlex="100">
            <mat-label>Je veux faire un don supplémentaire (en euros)</mat-label>
            <input
              matInput
              placeholder="Supplément"
              name="complement"
              type="number"
              min="0"
              formControlName="complement"
              [(ngModel)]="adhesion.montant_complementaire"
            />
              <mat-hint>Vous pouvez choisir d'ajouter un don libre au palier choisi, sans obligation.</mat-hint>
          </mat-form-field>
      </div>
      <p *ngIf="chooseBD()" style="font-weight: bold; margin-top: 20px">En sélectionnant une offre avec BD je donne mon accord pour que mon nom et prénom y apparaissent et pour m'inscrire à la newsletter de suivi du projet.</p>
      <div>
        <a mat-fab extended class="back-button" href="https://sociochaux.fr/adhesion-2024-2025/" target="_blank">En savoir +</a>
        <button
          mat-fab
          extended
          [disabled]="selectedPalier === 0"
          matStepperNext
          class="validate-button">
          Suivant
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step flex="" fxLayout="row" *ngIf="isConnected()">
    <ng-template matStepLabel
      >Vous êtes déjà connecté
      <mat-icon style="position: relative; top: 5px; color: green"
        >verified</mat-icon
      ></ng-template>
    <div>
      <button mat-fab extended matStepperPrevious class="back-button">Précédent</button>
      <button mat-fab extended matStepperNext class="validate-button">
        Suivant
      </button>
    </div>
  </mat-step>
  <mat-step
    [stepControl]="accountFormGroup"
    flex=""
    fxLayout="row"
    *ngIf="!isConnected()"
  >
    <form [formGroup]="accountFormGroup">
      <ng-template matStepLabel>Déjà adhérent ?</ng-template>
      <mat-chip-listbox
        *ngIf="!isConnected()"
        formControlName="accountCtrl"
        class="mat-mdc-chip-set-stacked"
        aria-label="Paliers sélectionnables"
        [(ngModel)]="hasAccount"
      >
        <mat-chip-option
          *ngFor="let status of accountStatus"
          [value]="status.id"
          [selectable]="hasAccount !== status.id"
          [selected]="isSelected(status.id)"
          >{{ status.text }}</mat-chip-option
        >
      </mat-chip-listbox>

      <div *ngIf="hasAccount === 2 && !isConnected()">
        <div flex="" fxLayout="row" fxLayoutAlign="center">
          <form class="connection-form" fxLayout="column">
            Vous avez indiqué être déjà adhérent, merci de vous connecter pour continuer l'adhésion
            <mat-card *ngIf="message === 'wrong_credentials'">
              <mat-card-content class="bad_credentials"
                >Identifiants incorrects</mat-card-content
              >
            </mat-card>
            <mat-form-field class="connection-full-width">
              <mat-label>Adresse email</mat-label>
              <input
                matInput
                [formControl]="emailFormControl"
                [errorStateMatcher]="matcher"
                type="email"
                placeholder="mon@adresse.fr"
                value=""
              />
              <mat-hint
                >L'adresse sur laquelle vous avez reçu votre email
                d'inscription</mat-hint
              >
              <mat-error
                *ngIf="
                  emailFormControl.hasError('email') &&
                  !emailFormControl.hasError('required')
                "
              >
                Merci d'entrer une adresse valide
              </mat-error>
              <mat-error *ngIf="emailFormControl.hasError('required')">
                L'email est <strong>obligatoire</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="connection-full-width">
              <mat-label>Mot de passe</mat-label>
              <input
                matInput
                [formControl]="passwordFormControl"
                placeholder="Mot de passe"
                type="password"
                value=""
              />
            </mat-form-field>
          </form>
        </div>
        <h1 flex="" fxLayout="row" fxLayoutAlign="center">
          <button mat-raised-button color="accent" (click)="connection()">
            Me connecter
          </button>
        </h1>
        <div flex="">&nbsp;</div>
        <div flex="" fxLayoutAlign="center"><button mat-stroked-button color="primary" (click)="openDialog()">J'ai perdu mon mot de passe</button></div>
        <div flex="">&nbsp;</div>
      </div>
      <div>
        <button mat-fab extended matStepperPrevious class="back-button">Précédent</button>
        <button
          mat-fab
          extended
          matStepperNext
          [disabled]="hasAccount !== 1"
          class="validate-button"
        >
          Suivant
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step
    [stepControl]="newSocioFormGroup"
    flex=""
    fxLayout="row"
    *ngIf="!isConnected()"
  >
    <ng-template matStepLabel>Coordonnées & informations</ng-template>
    <div
      *ngIf="hasAccount === 1 && !isConnected()"
      flex=""
      fxLayout="column"
      fxLayoutAlign="left"
    >
      <form [formGroup]="newSocioFormGroup" flex="" fxLayout="column">
        <div fxFlex="80" fxLayoutAlign="center">
          <mat-form-field fxFlex="100">
            <mat-label>Email</mat-label>
            <input
              matInput
              placeholder="Email"
              name="email"
              type="email"
              formControlName="email"
              (change)="updateUserForm()"
            />
          </mat-form-field>
        </div>
        <div
          fxLayout="column"
          fxLayout.gt-xs="row"
          fxLayoutAlign="center"
          formGroupName="phone"
        >
          <mat-form-field class="phone_country" fxFlex="50">
            <mat-label>Pays du n° téléphone</mat-label>
            <mat-select
              formControlName="country"
              name="country"
              (selectionChange)="formatNumber()"
              [value]="'France'"
              (valueChange)="updateUserForm()"
            >
              <mat-option
                *ngFor="let countryCode of countyCodes"
                [value]="countryCode.code"
              >
                {{ countryCode.country }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="phone_number" fxFlex="50">
            <mat-label>N° téléphone</mat-label>
            <input
              formControlName="number"
              type="tel"
              placeholder="Téléphone"
              name="phone"
              (input)="formatNumber()"
              [errorStateMatcher]="phoneErrorMatcher"
              (change)="updateUserForm()"
              matInput
            />
            <mat-error *ngIf="!phoneGroup.valid">
              Numéro valide: {{ phoneHint }}
            </mat-error>
            <mat-hint>{{ phoneHint }}</mat-hint>
          </mat-form-field>
        </div>
        <div fxFlex="80" fxLayoutAlign="center">
          <mat-form-field fxFlex="100">
            <mat-label>Nom de famille</mat-label>
            <input
              matInput
              placeholder="Nom de famille"
              name="nom"
              type="text"
              formControlName="nom"
              (change)="updateUserForm()"
            />
          </mat-form-field>
        </div>
        <div fxFlex="80" fxLayoutAlign="center">
          <mat-form-field fxFlex="100">
            <mat-label>Prénom</mat-label>
            <input
              matInput
              placeholder="Prénom"
              name="prenom"
              type="text"
              formControlName="prenom"
              (change)="updateUserForm()"
            />
          </mat-form-field>
        </div>
        <div fxFlex="80" fxLayoutAlign="center">
          <mat-form-field fxFlex="100">
            <mat-label>Date de naissance</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              name="date_naissance"
              formControlName="date_naissance"
              (dateChange)="updateUserForm()"
              readonly
            />
            <mat-hint>JJ/MM/AAAA</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex="80" fxLayoutAlign="center">
          <mat-form-field fxFlex="100">
            <mat-label>Numéro et rue</mat-label>
            <input
              matInput
              placeholder="Rue"
              name="adresse"
              type="text"
              formControlName="adresse"
              (change)="updateUserForm()"
            />
          </mat-form-field>
        </div>
        <div fxFlex="80" fxLayoutAlign="center">
          <mat-form-field fxFlex="100">
            <mat-label>Code postal</mat-label>
            <input
              matInput
              placeholder="Code postal"
              name="code_postal"
              type="text"
              formControlName="code_postal"
              (change)="updateUserForm()"
            />
          </mat-form-field>
        </div>
        <div fxFlex="80" fxLayoutAlign="center">
          <mat-form-field fxFlex="100">
            <mat-label>Ville</mat-label>
            <input
              matInput
              placeholder="Ville"
              name="ville"
              type="text"
              formControlName="ville"
              (change)="updateUserForm()"
            />
          </mat-form-field>
        </div>
        <div fxFlex="80" fxLayoutAlign="center">
          <mat-form-field fxFlex="100">
            <mat-label>Choisir le pays</mat-label>
            <mat-select formControlName="pays" name="pays" [value]="'France'" (valueChange)="updateUserForm()">
              <mat-option *ngFor="let country of countries" [value]="country.name">
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <button mat-fab extended matStepperPrevious class="back-button">Précédent</button>
          <button mat-fab extended matStepperNext class="validate-button" (click)="updateUserForm()">
            Suivant
          </button>
        </div>
      </form>
    </div>
  </mat-step>
  <mat-step flex="" fxLayout="column" *ngIf="socioSelection()">
    <ng-template matStepLabel>Socios et adhésions</ng-template>
    <div flex="" fxLayout="column" fxLayoutAlign="center">
      <div fxLayout="column">
        <p>Vous avez choisi la formule <strong>{{ getCurrentPalier()?.text }}</strong>, pour quels Socios ?</p>
        <div *ngFor="let socio of nonAdherent()" fxLayout="column">
            <p fxLayout="column*">
                <mat-form-field fxFlex="100">
                    <mat-label>{{ socio.techId }} - {{ socio.nom }} {{ socio.prenom }}</mat-label>
                    <select matNativeControl required (change)="setSocio(socio.id, $event)">
                        <option value="0">Non adhérent (0€)</option>
                        <option *ngFor="let palier of adhesionPaliers" [selected]="selectedPalier === palier.id" [value]="palier.id">{{ palier.text }} ({{palier.price}}€)</option>
                    </select>
                </mat-form-field>
            </p>
            <p fxHide.gt-xs *ngIf="getSocio(socio.id).contribution === 4">&nbsp;</p>
        </div>
        
        <div>
          <button mat-fab extended matStepperPrevious class="back-button">Précédent</button>
          <button mat-fab extended matStepperNext class="validate-button">
            Suivant
          </button>
        </div>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Vérification et validation</ng-template>
    <p *ngIf="hasTransport()">Veuillez sélectionner le mode de livraison de votre BD</p>
    <p *ngIf="hasTransport()">
      <mat-form-field fxFlex="100">
        <select matNativeControl required [(ngModel)]="adhesion.choice_transport">
            <option disabled selected>Choix du mode de livraison</option>
            <option *ngFor="let palier of transportsPaliers" [ngValue]="palier.id">{{ palier.text }} ({{ palier.price }}€)</option>
        </select>
      </mat-form-field>
    </p>
    <p *ngIf="!hasTransport() || adhesion.choice_transport > 0">Nous avons toutes les informations nécessaires pour votre adhésion.</p>
    <p>Veuillez trouver ici un récapitulatif de votre sélection avant de procéder au paiement :</p>
    <table mat-table [dataSource]="summaryToDisplay()">
        <ng-container matColumnDef="text">
            <th mat-header-cell *matHeaderCellDef> Sélection(s) </th>
            <td mat-cell *matCellDef="let element"> {{element.text}} </td>
          </ng-container>
          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Quantité </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;"> {{totalDisplay(element.id, element.number, false)}} </td>
          </ng-container>
          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Total </th>
            <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.total}}€{{totalDisplay(element.id, element.complement, true)}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['text', 'number', 'total']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['text', 'number', 'total'];"></tr>
    </table>
    <p>&nbsp;</p>
    <p><strong>EN SELECTIONNANT "Paiement" JE CERTIFIE QUE LES INFORMATIONS CI-DESSUS SONT CORRECTES</strong></p>
    <p>&nbsp;</p>
    <div>
      <button mat-fab extended matStepperPrevious class="back-button">Précédent</button>
      <button mat-fab extended class="validate-button" (click)="sendAdhesion()" [disabled]="hasTransport() && adhesion.choice_transport === 0">
        Paiement
      </button>
    </div>
  </mat-step>
</mat-stepper>
<p *ngIf="messagePaiement !== undefined && messageIntent !== undefined">
  <mat-card appearance="outlined" *ngIf="messageStatus === 'waiting'">
    En attente de validation, merci de patienter
    <mat-card-content style="margin-left: auto; margin-right: auto"><mat-spinner></mat-spinner></mat-card-content>
  </mat-card>
  <mat-card appearance="outlined" *ngIf="messageStatus === 'ok'">
    <span style="color:forestgreen; margin-left: auto; margin-right: auto; font-size: large; font-weight: bold;">Félicitations !</span>
    <mat-card-content style="margin-left: auto; margin-right: auto">
      <p>Votre adhésion est maintenant totalement validée !</p>
      <p>Un email récapitulatif vous a été envoyé à l'adresse <strong>{{messageEmail}}</strong></p>
      <p>En cas de problème vous pouvez nous contacter à l'adresse {{'contact@sociochaux.fr'}}</p>
      <p>Merci encore pour votre soutien</p>
      <p>- Toute l'équipe Sociochaux</p>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined" *ngIf="messageStatus === 'canceled'">
    <span style="color:darkred; margin-left: auto; margin-right: auto; font-size: large; font-weight: bold;">Paiement annulé</span>
    <mat-card-content style="margin-left: auto; margin-right: auto">
      <p>Votre adhésion n'a pas pu être validée par la banque et aucun montant n'a été débité.</p>
      <p>Un email d'annulation vous a été envoyé à l'adresse que vous avez indiquée</p>
      <p>En cas de problème vous pouvez nous contacter à l'adresse {{'contact@sociochaux.fr'}}</p>
      <p>Merci encore pour votre compréhension</p>
      <p>- Toute l'équipe Sociochaux</p>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined" *ngIf="messageStatus === 'error'">
    <span style="color:darkred; margin-left: auto; margin-right: auto; font-size: large; font-weight: bold;">Une erreur s'est produite</span>
    <mat-card-content style="margin-left: auto; margin-right: auto">
      <p>Votre adhésion n'a pas pu être validée suite à une erreur technique et aucun montant n'a été débité.</p>
      <p>Un email d'annulation vous a été envoyé à l'adresse <strong>{{messageEmail}}</strong></p>
      <p>En cas de problème vous pouvez nous contacter à l'adresse {{'contact@sociochaux.fr'}}</p>
      <p>Merci encore pour votre compréhension</p>
      <p>- Toute l'équipe Sociochaux</p>
    </mat-card-content>
  </mat-card>
</p>