import { Component, Inject, AfterViewInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConnectorService } from '../connector.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentDto } from './document.dto';
import { environment } from '../../environments/environment';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { MatIconModule } from '@angular/material/icon';

// Utiliser le worker correspondant à la version de `pdfjsLib`
(pdfjsLib as any).GlobalWorkerOptions.workerSrc = pdfjsWorker;

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
  fileList: DocumentDto[] = [];
  fileListObs: Observable<DocumentDto[]> = new Observable();

  constructor(
    private http: HttpClient,
    private connector: ConnectorService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ){}

  ngOnInit(): void {
    if(!this.connector.isConnected()){
      this.connector.disconnection();
    }else{
      this.getDocuments();
    }
  }
  
  async getDocuments(){
    const _this = this;
    this.fileListObs = await this.http.get<DocumentDto[]>(environment.backAPI+'/documents/public',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    });
    this.fileListObs.subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          _this.fileList = result;
          const testId = _this.activatedRoute.snapshot.params['id'];
          if(testId !== undefined){
            _this.fileDownload(Number(testId));
          }
          return result;
        }
        else{
          this.connector.disconnection();
          return [];
        }
      },
      (error) => {
        this.connector.disconnection();
        return [];
      }
    );
  }

  formatBytes(bytes:number,decimals: number) {
    if(bytes == 0) return '0 Bytes';
    var k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  async fileDownload(id:number){
    const fileToDispley = await this.fileList.find(x => x.id == id);
    if(fileToDispley !== null && fileToDispley !== undefined){
      try {
        const _this = this;
        // Étape 1: Faire une requête avec le token Bearer
        const response = await fetch(environment.backAPI+'/documents/public/'+id, {
          headers: {
            'Authorization': 'Bearer '+this.connector.token()
          }
        });
  
        if (!response.ok) {
          console.error('Erreur de chargement du document', response.status);
          return;
        }
  
        // Étape 2: Convertir la réponse en Blob
        const blob = await response.blob();
  
        // Étape 3: Convertir le Blob en Base64
        const reader = new FileReader();
        reader.onloadend = function () {
          // Lecture du résultat et conversion en base64
          const base64data = (reader.result as string).split(',')[1];

          // Étape 4: Créer l'URL en base64
          const base64Url = base64data;

          _this.openDialog(fileToDispley, 'data:'+fileToDispley.type+';base64,'+base64Url);
        }

        // Lire le blob comme URL base64
        reader.readAsDataURL(blob);
      }catch(error){
        console.error(error);
        alert("Une erreur s'est produite");
      }
    }else{
      alert("Ce document n'est pas trouvé dans la liste des documents disponibles")
    }
  }

  openDialog(document:DocumentDto, file64:string): void {
    if(document.type === 'application/pdf'){
      const dialogRef = this.dialog.open(DialogPDF, {
        data: {
          document: document,
          url: file64
        }
      });
    }else{
      const dialogRef = this.dialog.open(DialogEmbeded, {
        data: {
          document: document,
          url: this.sanitizer.bypassSecurityTrustResourceUrl(file64)
        }
      });
    }
  }
}

@Component({
  selector: 'embed-dialog',
  templateUrl: 'embed.html',
  styleUrls: ['./embed.css'],
  standalone: true,
  imports: [MatDialogModule, NgIf, MatButtonModule, FlexLayoutModule, MatIconModule],
})
export class DialogPDF implements AfterViewInit {
  isPDF: boolean = true;
  pdf: any; // Référence à l'objet PDF
  currentPage: number = 1; // Numéro de la page actuelle
  totalPages: number = 0; // Nombre total de pages
  constructor(
    public dialogRef: MatDialogRef<DialogEmbeded>,
    @Inject(MAT_DIALOG_DATA) public data: {document: DocumentDto, url: string},
  ) {}

  ngAfterViewInit(): void {
    if (this.data.url) {
      this.renderPDF(this.data.url, this.currentPage);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async renderPDF(pdfUrl: string, pageNumber: number): Promise<void> {
    try {
      const loadingTask = pdfjsLib.getDocument(pdfUrl);
      this.pdf = await loadingTask.promise; // Charger le PDF
      this.totalPages = this.pdf.numPages; // Obtenir le nombre total de pages du PDF
  
      const page = await this.pdf.getPage(pageNumber); // Charger la page demandée
  
      // Ajuster le scale pour s'assurer que le rendu est proportionné
      const scale = 1.0;
      const viewport = page.getViewport({ scale });
  
      const canvas: HTMLCanvasElement = document.getElementById('pdf-canvas') as HTMLCanvasElement;
      const context = canvas.getContext('2d');
  
      // Définir la largeur et la hauteur du canvas en fonction du viewport
      canvas.width = viewport.width;
      canvas.height = viewport.height;
  
      const renderContext = {
        canvasContext: context!,
        viewport: viewport,
      };
  
      await page.render(renderContext).promise; // Effectuer le rendu du PDF
    } catch (error) {
      console.error('Erreur lors du rendu du PDF :', error);
    }
  }  

  // Méthode pour aller à la page suivante
  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.renderPDF(this.data.url, this.currentPage);
    }
  }

  // Méthode pour revenir à la page précédente
  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.renderPDF(this.data.url, this.currentPage);
    }
  }

  base64ToUint8Array(base64: string): Uint8Array {
    const raw = window.atob(base64);
    const uint8Array = new Uint8Array(raw.length);
    for (let i = 0; i < raw.length; i++) {
      uint8Array[i] = raw.charCodeAt(i);
    }
    return uint8Array;
  }
}

@Component({
  selector: 'embed-dialog',
  templateUrl: 'embed.html',
  styleUrls: ['./embed.css'],
  standalone: true,
  imports: [MatDialogModule, NgIf, MatButtonModule, FlexLayoutModule, MatIconModule],
})
export class DialogEmbeded {
  isPDF: boolean = false;
  pdf: any; // Référence à l'objet PDF
  currentPage: number = 1; // Numéro de la page actuelle
  totalPages: number = 0; // Nombre total de pages
  constructor(
    public dialogRef: MatDialogRef<DialogEmbeded>,
    @Inject(MAT_DIALOG_DATA) public data: {document: DocumentDto, url: SafeResourceUrl},
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  nextPage(): void {}

  // Méthode pour revenir à la page précédente
  prevPage(): void {}
}
