import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

export class EncodeResult {
  token: string = "";
  expires: number = 0;
  issued: number = 0;
  id: number = 0;
  email: string = "";
  username: string = "";
  isSuperAdmin: boolean = false;
  isAdmin: boolean = false;
  isActive: boolean = false;
  roles: {
    idCompte: number,
    isAdherent: boolean,
    isSocio: boolean,
    isUtilisateur: boolean
  } = { idCompte: 0, isAdherent: false, isSocio: false, isUtilisateur: false };
}

export class LogInBackDto {
  status: number = 0;
  message: string = "";
  content: EncodeResult|undefined = undefined;
}

export enum UserVariable {
  TOKEN = 'token',
  EXPIRES = 'expires',
  ISSUED = 'issued',
  ID = 'id',
  EMAIL = 'email',
  USERNAME = 'username',
  ISACTIVE = 'isActive',
  PATH = 'path',
  ROLES = 'roles',
}

@Injectable({
  providedIn: 'root'
})
export class ConnectorService {
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  async connection(login:string|null, password:string|null){
    await this.http.post<LogInBackDto>(environment.backAPI+'/compte/login', {
      email: login,
      password: password
    }).subscribe(
      (result) => {
        if(result.status === 200 && result.content != undefined){
          this.initSession(result);
          this.connectedNavigation();
        }
        else{
          this.router.navigateByUrl('/login?message=wrong_credentials');
        }
      },
      (error) => {
        this.router.navigateByUrl('/login?message=wrong_credentials');
      }
    );
  }

  async inline_connection(login:string|null, password:string|null, parent:any, postConnection?: Function){
    return await this.http.post<LogInBackDto>(environment.backAPI+'/compte/login', {
      email: login,
      password: password
    }).subscribe(
      (result) => {
        if(result.status === 200 && result.content != undefined){
          this.initSession(result);
          parent.message = "OK";
          if(postConnection) postConnection(true);
        }
        else{
          parent.message = "wrong_credentials";
        }
      },
      (error) => {
        parent.message = "wrong_credentials";
      }
    );
  }

  connectedNavigation(){
    if(this.isJourney()){
      this.journeyComeback();
    }else{
      if(this.getValue(UserVariable.ISACTIVE) === 'Y'){
        this.router.navigate(['/home', { }]);
      }else{
        this.router.navigate(['/account', { }]);
      }
    }
  }

  journeyConnection(){
    localStorage.setItem(UserVariable.PATH, this.router.url);
    console.log(this.router.url);
    this.router.navigateByUrl('/login');
  }

  isJourney(){
    return localStorage.getItem(UserVariable.PATH) !== null;
  }

  journeyComeback(){
    const path = localStorage.getItem(UserVariable.PATH);
    localStorage.removeItem(UserVariable.PATH);
    console.log(path);
    if(path){
      this.router.navigateByUrl(path);
    }else{
      this.router.navigateByUrl('/home');
    }
  }

  initSession(result: LogInBackDto){
    if(result.content != undefined){
      localStorage.setItem(UserVariable.TOKEN, result.content.token);
      localStorage.setItem(UserVariable.EXPIRES, result.content.expires.toString());
      localStorage.setItem(UserVariable.ISSUED, result.content.issued.toString());
      localStorage.setItem(UserVariable.ID, result.content.id.toString());
      localStorage.setItem(UserVariable.EMAIL, result.content.email);
      localStorage.setItem(UserVariable.USERNAME, result.content.username);
      localStorage.setItem(UserVariable.ISACTIVE, (result.content.isActive)?'Y':'N');
      localStorage.setItem(UserVariable.ROLES, JSON.stringify(result.content.roles));
    }
  }

  disconnection(){
    this.http.post(environment.backAPI+'/notifications/unsubscription', {}, {
      headers: {
        'Authorization': 'Bearer '+this.token()
      }
    }).subscribe();

    localStorage.removeItem(UserVariable.TOKEN);
    localStorage.removeItem(UserVariable.EXPIRES);
    localStorage.removeItem(UserVariable.ISSUED);
    localStorage.removeItem(UserVariable.ID);
    localStorage.removeItem(UserVariable.EMAIL);
    localStorage.removeItem(UserVariable.USERNAME);
    localStorage.removeItem(UserVariable.ISACTIVE);
    localStorage.removeItem(UserVariable.ROLES);
    this.journeyConnection();
  }

  async check(){
    await this.http.post<LogInBackDto>(environment.backAPI+'/compte/check', {
      token: localStorage.getItem('token')
    }).subscribe(
      (result) => {
        if(result.status === 200 && result.content!= undefined){
          return true;
        }
        else{
          this.disconnection();
          return false;
        }
      }
    );
  }

  getId(){
    return (localStorage.getItem('id') === null)?0:Number(localStorage.getItem('id'));
  }

  getValue(variableName:UserVariable):any{
    const value:string|null = localStorage.getItem(variableName);
    if(value === null) return '';
    return value;
  }

  isConnected(){
    return this.token() !== undefined
  }

  isAdherent(){
    const roles = localStorage.getItem(UserVariable.ROLES);
    if(roles === null || roles === 'null') return false;
    return this.isConnected() && JSON.parse(roles).isAdherent;
  }

  isSocio(){
    const roles = localStorage.getItem(UserVariable.ROLES);
    if(roles === null || roles === 'null') return false;
    return this.isConnected() && (roles)?JSON.parse(roles).isSocio:false;
  }

  token(){
    if(localStorage.getItem('token')){
      return localStorage.getItem('token');
    }
    return undefined;
  }

  async reinit(email:string, code:string){
    await this.http.post<boolean>(environment.backAPI+'/compte/reinit-pwd', {
      email: email,
      code: code
    }).subscribe(
      (result) => {
        return result;
      }
    );
  }
}
