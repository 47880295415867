import { Component, inject } from '@angular/core';
import { ConnectorService } from '../connector.service';
import { NavigationEnd, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from '../notifications.service';

class navMenu {
  link: string = '';
  title: string = '';
  icon: string = '';
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  isConnected = true;
  readonly connectedMenus: navMenu[] = [];
  menuToDisplay: navMenu[] = [];

  deferredPrompt: any = null;

  constructor(
    private connector: ConnectorService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    public notificationsService: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.setIsConnected();
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        this.setIsConnected();
        if(this.connector.isConnected()){
          setTimeout(() => {
            this.notificationsService.getInAppNotifications();
          }, 1000);
        }
      }
    });
    this.checkInstall();
    if(this.connector.isConnected()){
      setTimeout(() => {
        this.notificationsService.getInAppNotifications();
      }, 1000);
    }
  }

  getHidden(num:number, receivePronoNotifications?:boolean){
    const displayProno = (receivePronoNotifications === undefined || receivePronoNotifications === true);
    if(num > 0 && displayProno){
      return 'false';
    }
    return 'hidden';
  }

  isInstallable(){
    return this.isMobile() && !this.isRunningInCapacitor() && !this.isRunningAsPWA();
  }

  // Detects if device is on iOS
  isIos(){
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod|macintosh/.test(userAgent);
  }

  isRunningInCapacitor(): boolean {
    return !!(window as any).Capacitor;
  }

  isRunningAsPWA(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches;
  }
                                
  // Detects if device is on iOS
  isSafari(){
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;
  }

  isMobile = () => {
    return window.innerWidth < 1025
  }

  hasNoNotifications(){
    const total = this.notificationsService.notifications.unreadDocs + this.notificationsService.notifications.missingPronostics + this.notificationsService.notifications.compteIssues + this.notificationsService.notifications.socioIssues + this.notificationsService.notifications.consultationsAvailable;
    return total === 0;
  }

  logoClick(){
    if(this.isConnected && this.hasNoNotifications() && this.isMobile()){
      this.router.navigate(['/game']);
    }
  }

  checkInstall() {
    const _this = this;
    let installPrompt:any = null;
    const installButton:any = document.querySelector("#install");
    if(!_this.isMobile()){
      installButton.style.display = 'none';
    }

    window.addEventListener("beforeinstallprompt", (event: Event) => {
      event.preventDefault();
      installPrompt = event;
      installButton.removeAttribute("hidden");
      installButton.style.display = 'block'; // assure l'affichage si on est hors Capacitor
    });

    installButton.addEventListener("click", async () => {
      if(_this.isIos()){
        _this._snackBar.openFromComponent(IosSnackBarAnnotatedComponent);
      }else{
        if (!installPrompt) {
          return;
        }
        const result = await installPrompt.prompt();
        console.log(`Install prompt was: ${result.outcome}`);
        installPrompt = null;
        installButton.setAttribute("hidden", "");
      }
    });
  }

  setIsConnected() {
    this.isConnected = this.connector.isConnected();
    if (this.isConnected) {
      this.menuToDisplay = this.connectedMenus;
    } else {
      this.menuToDisplay = [];
    }
  }

  isHome(){
    return this.router.url === '/home';
  }

  colorHere(link: string) {
    if(this.router.url === link){
      return 'accent';
    }
    return 'primary';
  }

  disconnect() {
    this.connector.disconnection();
  }
}

@Component({
  selector: 'ios-snackbar',
  templateUrl: 'ios.snackbar.html',
  styles: [],
  standalone: false
})
export class IosSnackBarAnnotatedComponent {
  snackBarRef = inject(MatSnackBarRef);
}
