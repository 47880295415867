/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('custom-service-worker.js')
    .then(reg => console.log('Service Worker enregistré:', reg))
    .catch(err => console.error('Erreur d’enregistrement du Service Worker:', err));
}
