<div fxLayout="column" flex="100" class="central-column">
    <h1 flex="100" fxLayout="row" fxLayoutAlign="center">1. Je choisis mon Socio qui s'exprime</h1>
    <div flex="100" fxLayout="row" fxLayoutAlign="left">
        <mat-form-field fxFlex="100">
            <mat-label>Mon profil :</mat-label>
            <mat-select [(ngModel)]="socioId" (valueChange)="changeSocio($event)">
                <mat-option *ngFor="let socio of account.socios" [value]="socio.id">{{socio.techId}} - {{socio.prenom}} {{socio.nom}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    
    <h1 *ngIf="jetonsList.length > 0" flex="100" fxLayout="row" fxLayoutAlign="center">2. Je choisis ma consultation</h1>
    <div *ngIf="jetonsList.length > 0" flex="100" fxLayout="row" fxLayoutAlign="left">
        <mat-form-field fxFlex="100">
            <mat-label>Mes consultations :</mat-label>
            <mat-select [(ngModel)]="consultationId" (valueChange)="changeJeton($event)">
                <mat-option *ngFor="let jeton of jetonsList" [value]="jeton.consultation.id" [ngStyle]="jetonStyle(jeton.status)">{{jeton.consultation.titre}} ({{ statusJeton(jeton.status) }})</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <h1 *ngIf="jetonsList.length === 0" flex="100" fxLayout="row" fxLayoutAlign="center">Aucune consultation disponible en ce moment</h1>
    
    <h1 *ngIf="consultation.id > 0" flex="100" fxLayout="row" fxLayoutAlign="center">3. Votre consultation</h1>
    <div *ngIf="consultation.id > 0" flex="100" fxLayout="column" fxLayoutAlign="left">
        <mat-card class="fixed" *ngIf="isSelected(consultation.id)">
            <mat-card-content><mat-icon>toll</mat-icon> Crédits restants : {{ remainingCredits() }}</mat-card-content>
        </mat-card>
        <h2 fxFlex="100" fxLayout="row" fxLayoutAlign="center">{{ consultation.titre }}</h2>
        <h4 fxFlex="100" fxLayout="row" fxLayoutAlign="center" style="font-style: italic;">Disponible du {{ displayDate(consultation.date_debut) }} jusqu'au {{ displayDate(consultation.date_fin) }}</h4>
        <p fxFlex="100" fxLayout="row" fxLayoutAlign="left"><strong>Description:&nbsp;</strong></p>
        <p fxFlex="100" fxLayout="column" style="-webkit-hyphens: auto; hyphens: auto;" fxLayoutAlign="left" [innerHTML]="consultation.description"></p>
        <p *ngIf="isNotOpen()" fxFlex="100" fxLayout="row" fxLayoutAlign="left"><strong>Cette consultation n'est actuellement pas disponible.</strong></p>
        <p *ngIf="isExpired()" fxFlex="100" fxLayout="row" fxLayoutAlign="left"><strong>Cette consultation est close et vous n'avez pas soumis de réponse à ce formulaire.</strong></p>
        <p *ngIf="isUsed()" fxFlex="100" fxLayout="row" fxLayoutAlign="left"><strong>Vous avez répondu à cette consultation le {{ displayDate(jeton!.date_utilisation) }}</strong></p>
        <div *ngIf="isCreditsFinished() || isValeurFinished()" fxFlex="100" fxLayout="column" fxLayoutAlign="center">
            <h2 fxFlex="100" fxLayout="column" fxLayoutAlign="center">Résultats</h2>
            <div #chartContainer fxFlex="100" fxLayout="column" fxLayoutAlign="center" style="width: 100%;">
                
                <!-- Affichage pour les crédits (un seul graphique) -->
                <ngx-charts-bar-horizontal *ngIf="isCreditsFinished()"
                    [view]="[chartWidth, currentResults.length * 50]"
                    [scheme]="'cool'"
                    [results]="currentResults"
                    [gradient]="gradient"
                    [xAxis]="showXAxis"
                    [yAxis]="showYAxis"
                    [legend]="false"
                    [showDataLabel]="true"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel">
                </ngx-charts-bar-horizontal>
        
                <!-- Affichage pour les valeurs (liste de graphiques) -->
                <div *ngIf="isValeurFinished()">
                    <div *ngFor="let result of listOfCurrentResults" style="width: 100%; margin-bottom: 10px;">
                        <h3>{{ result.title }} :</h3>
                        <ngx-charts-bar-horizontal
                            [view]="[chartWidth, result.values.length * 50]"
                            [scheme]="'cool'"
                            [results]="result.values"
                            [gradient]="gradient"
                            [xAxis]="showXAxis"
                            [yAxis]="showYAxis"
                            [legend]="false"
                            [showDataLabel]="true"
                            [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel"
                            [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel">
                        </ngx-charts-bar-horizontal>
                    </div>
                </div>
            </div>
        </div>        
        <div *ngIf="isCredits()" fxFlex="100" fxLayout="column" fxLayoutAlign="center">
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="center" *ngFor="let question of consultation.questions">
                <div fxFlex="100" fxLayout="column" fxLayoutAlign="center">&nbsp;</div>
                <mat-divider></mat-divider>
                <div fxFlex="100" fxLayout="column" fxLayoutAlign="center">&nbsp;</div>
                <h3 fxFlex="100">Question {{ question.ordre+1 }}: {{ question.titre }}</h3>
                <p fxFlex="100" [innerHTML]="question.description"></p>
                <p fxFlex="100">Lien: <a target="_blank" href="{{question.lien}}">{{ question.lien }}</a></p>
                <mat-form-field fxFlex="100">
                    <mat-label>Crédits que vous allouez à "{{ question.titre }}"</mat-label>
                    <input fxFlex="100" fxLayout="row" fxLayoutAlign="left" matInput type="number" min="0" (change)="changeCredits(question.id, $event)">
                    <mat-hint *ngIf="question.obligatoire">Ce champ est obligatoire</mat-hint>
                </mat-form-field>
                <div fxLayout="row" flex="100" *ngIf="question.volontariat">
                    <div fxFlex="50" fxLayoutAlign="end center">Souhaiteriez-vous participer ?&nbsp;</div>
                    <div fxFlex="50">
                      <mat-slide-toggle (value)="false" (change)="changeVolontariat(question.id, $event)">
                        <span *ngIf="volontariat[question.id] !== undefined && volontariat[question.id]" style="color:green; font-weight: bolder;">Oui</span>
                        <span *ngIf="volontariat[question.id] === undefined || !volontariat[question.id]" style="color:red; font-weight: bolder;">Non</span>
                      </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="center">&nbsp;</div>
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="center">
                <div style="line-break: loose; text-align: justify">
                    En cliquant sur le bouton ci-dessous, je reconnais avoir pris connaissance de l'intégralité des questions et documents proposés et avoir conscience que je ne pourrai plus modifier mes réponses ultérieurement :
                </div>
            </div>
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="center">
                <button mat-raised-button color="primary" [disabled]="remainingCredits() !== 0" (click)="consultationValidation()">JE CONFIRME</button>
            </div>
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center">(Il est nécessaire de consommer tous les crédits afin de pouvoir confirmer)</div>
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="center" class="bottom">&nbsp;</div>
        </div>

        <div *ngIf="isValeur()" fxFlex="100" fxLayout="column" fxLayoutAlign="center">
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="center" *ngFor="let question of consultation.questions">
                <div fxFlex="100" fxLayout="column" fxLayoutAlign="center">&nbsp;</div>
                <mat-divider></mat-divider>
                <div fxFlex="100" fxLayout="column" fxLayoutAlign="center">&nbsp;</div>
                <h3 fxFlex="100">Question {{ question.ordre+1 }}: {{ question.titre }}</h3>
                <p fxFlex="100" [innerHTML]="question.description"></p>
                <p fxFlex="100">Lien: <a target="_blank" href="{{question.lien}}">{{ question.lien }}</a></p>
            
                <mat-form-field fxFlex="100">
                    <mat-label>Votre choix:</mat-label>
                    <mat-select (valueChange)="changeValeurs(question.id, $event)">
                        <mat-option value="0">Je ne me prononce pas</mat-option>
                        <mat-option value="1">Oui</mat-option>
                        <mat-option value="2">Non</mat-option>
                    </mat-select>
                    <mat-hint *ngIf="question.obligatoire">Ce champ est obligatoire</mat-hint>
                </mat-form-field>

                <div fxLayout="row" flex="100" *ngIf="question.volontariat">
                    <div fxFlex="50" fxLayoutAlign="end center">Souhaiteriez-vous participer ?&nbsp;</div>
                    <div fxFlex="50">
                      <mat-slide-toggle (value)="false" (change)="changeVolontariat(question.id, $event)">
                        <span *ngIf="volontariat[question.id] !== undefined && volontariat[question.id]" style="color:green; font-weight: bolder;">Oui</span>
                        <span *ngIf="volontariat[question.id] === undefined || !volontariat[question.id]" style="color:red; font-weight: bolder;">Non</span>
                      </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="center">&nbsp;</div>
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="center">
                <div style="line-break: loose; text-align: justify">
                    En cliquant sur le bouton ci-dessous, je reconnais avoir pris connaissance de l'intégralité des questions et documents proposés et avoir conscience que je ne pourrai plus modifier mes réponses ultérieurement :
                </div>
            </div>
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="center">
                <button mat-raised-button color="primary" (click)="consultationValidation()">JE CONFIRME</button>
            </div>
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="center" class="bottom">&nbsp;</div>
        </div>
    </div>
</div>