import { Jeton } from "../consultations/consultations.dto";

export enum ProfileStatus {
    INACTIVE = "inactive",
    FIRSTCONNECTION = "first_connection",
    ACTIVE = "active",
    BLOCKED = "blocked",
}

export class Cotisation {
    id:number = 0;
    techId:number = 0;
    techId_socio:number = 0;
    date_adhesion: Date = new Date();
    somme: number = 0;
    numero_attestation: number = 0;
}

export class PhoneNumber{
    input: string = "";
    international: string = "";
    national: string = "";
    e164: string = "";
    rfc3966: string = "";
    significant: string = "";
}

export class Commande {
    idCommande: number = 0;
    idCompte: number = 0;
    date:Date = new Date();
    nom: string = "";
    prenom: string = "";
    idTechSocio: number = 0;
    taille: string = "";
    email: string = "";
    commande: string = "";
    montant: number = 0;
    livraison: string = "";
    fdp: string = "";
    adresse: string = "";
    codePostal: string = "";
    commune: string = "";
    pays: string = "";
    moyenPaiement: string = "";
    telephone: string = "";
    idPdf: number = 0;
}

export class Phone {
    number: PhoneNumber = new PhoneNumber();
    regionCode: string = "";
    valid: boolean = false;
    possible: boolean = false;
    canBeInternationallyDialled: boolean = false;
    type: string = "";
    possibility: string = "";
}
export class StatutsSocio{
    idSocio: number = 0;
    isAdherent: boolean = false;
    adhesionDaysBeforeExpiration: number = 0;
    isSocio: boolean = false;
    isUtilisateur: boolean = false;
}
export class Socio {
    id:number = 0;
    techId:number = 0;
    techIdUtilisateur:number = 0;
    nom: string = "";
    prenom: string = "";
    mail: string = "";
    date_naissance: Date = new Date();
    adresse: string = "";
    code_postal: string = "";
    ville: string = "";
    pays: string = "";
    card_suffix: string = "";
    utilisateur: any = {};
    cotisation: Cotisation = new Cotisation();
    maillot: boolean = false;
    jetons: Jeton[] = [];
    roles: StatutsSocio = new StatutsSocio();
    carte_publique: string = "";
    carte_privee: string = "";
}


export class Account {
    id:number = 0;
    email: string = "";
    username: string = "";
    status: ProfileStatus = ProfileStatus.INACTIVE;
    phone: Phone = new Phone();
    profile: string = "";
    socios: Socio[] = [];
    commandes: Commande[] = [];
}